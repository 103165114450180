<template>
  <portlet class="kt-iconbox" :class="portletOtherClass">
    <template slot="body">
      <div class="kt-iconbox__body">
        <div class="kt-iconbox__icon" v-if="hasIconSlot || icon">
          <slot name="icon" v-if="hasIconSlot"></slot>
          <i :class="iconClass" v-if="! hasIconSlot && icon"></i>
        </div>
        <div class="kt-iconbox__desc" v-if="hasDesc">
          <h3 class="kt-iconbox__title" v-if="hasTitleSlot || title">
            <slot name="title" v-if="hasTitleSlot"></slot>
            <template v-if="! hasTitleSlot ">{{ title }}</template>
          </h3>
          <div class="kt-iconbox__content" v-if="hasContentSlot || content">
            <slot name="content" v-if="hasContentSlot"></slot>
            <template v-if="content">{{ content }}</template>
          </div>
        </div>
      </div>
    </template>
  </portlet>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  export default {
    name: "IconBox",
    props: {
      color: {
        type: String,
        default: ()=> 'wave'
      },
      animate: {
        type: String,
        default: ()=> '',
        validator: function (value) {
          return ['', null, 'animate', 'animate-slower', 'animate-slow', 'animate-fast', 'animate-faster'].includes(value)
        }
      },
      title: {
        type: String,
        default: ()=> null
      },
      content: {
        type: String,
        default: ()=> null
      },
      icon: {
        type: String,
        default: ()=> null
      },
    },
    components: { Portlet },
    computed: {
      portletOtherClass(){
        return [
          "kt-iconbox--" + this.color,
          this.animate ? "kt-iconbox--" + this.animate : ''
        ]
      },
      hasIconSlot(){
        return !!this.$slots["icon"];
      },
      hasTitleSlot(){
        return !!this.$slots["title"];
      },
      hasContentSlot(){
        return !!this.$slots["content"];
      },
      hasDesc(){
        return this.hasTitleSlot || this.hasContentSlot || this.title || this.content;
      },
      iconClass(){
        return [this.icon, 'text-' + this.color]
      },
    }
  }
</script>

<style>
  .kt-iconbox .kt-iconbox__body .kt-iconbox__icon i {
    height: 50px;
    width: 50px;
    font-size: 50px;
  }
</style>
