<template>
  <div class="ratio-modal">
    <base-modal :id="id" :title="title" :modal-busy="userBusy" :size="size" v-model="modalState" >
      <b-row>
        <b-col md="12" sm="6">
          <b-form-group
            id="input-group-ratio"
            label="پايدا نىسبىتى"
            label-for="ratio"
          >
            <b-input-group prepend="%">
              <b-form-spinbutton class="direction-rtl" id="ratio" step="0.5" max="100" v-model="formData.ratio" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12" sm="6" v-if="enableList.includes('contract_reward_permission')">
          <b-form-group
                  id="input-group-entrust_reward"
                  label="كېلىشىم ئەزالىق مۇكاپات سوممىسى"
                  label-for="entrust_reward"
                  description="پەقەت پۇل سوممىسىنىلا كىرگۈزۈڭ، بىرلىكىنى كىرگۈزمەڭ، مەسىلەن يۈەن، دوللار دىگەندەكلەرنى كىرگۈزمەڭ"
          >
            <multi-select
                    id="entrust_reward"
                    placeholder="بىر باھانى يېزىپ قايتۇرۇش كونۇپكىسىنى باسسىڭىز بولىدۇ ."
                    select-label="قايتۇرۇش كونۇپكىسىنى بېسىڭ ياكى مائوستا تاللاڭ"
                    selected-label="تاللانغان"
                    deselect-label="قايتۇرۇش كونۇپكىسىنى  بېسىڭ ياكى مائوستا تاللاپ ئۆچۈرۈڭ"
                    tag-position="bottom"
                    :multiple="true"
                    label="name"
                    track-by="name"
                    :taggable="true"
                    tagPlaceholder="قايتۇرۇش كونۇپكىسىنى بېسىپ قوشۇڭ"
                    @tag="addEntrustReward"
                    :options="formData.entrust_reward"
                    v-model="formData.entrust_reward" />
          </b-form-group>
        </b-col>
        <b-col md="12" sm="6">
          <icon-box :content="membershipTips" />
        </b-col>
      </b-row>
      <b-button @click="onSubmit" id="submit" size="sm" variant="primary">
        ساقلاش
      </b-button>
    </base-modal>
  </div>
</template>

<script>
  import baseModal from "@v@/components/modals/base.modal";
  import IconBox from "@v@/partials/content/IconBox";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import userMixin from "@m@/user.mixin";
  import "vue-multiselect/dist/vue-multiselect.min.css";
  import MultiSelect from 'vue-multiselect';
  import {mapGetters} from "vuex";
  export default {
    name: "UserRewardRatioModal",
    props: {
      id: {
        type: String,
        default: () => 'reward-ratio-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'پايدا نىسبىتى تەڭشەش'
      },
      size: {
        type: String,
        default: ()=> 'lg'
      },
      options: {
        type: Object
      },
      userId: Number
    },
    components: {IconBox, baseModal, MultiSelect },
    mixins: [ formBusyMixin, userMixin ],
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      ...mapGetters(["enableList"])
    },
    watch: {
      options: {
        immediate: true,
        handler(options){
          if ( options !== this.formData ){
            if ( ! options ){
              this.formData = {
                ratio: '',
                entrust_reward: [],
                membership_reward: '',
                user_id: this.userId
              };
            }else {
              this.formData = options;
            }
          }
        }
      }
    },
    data(){
      return {
        userBusy: false,
        formData: {
          ratio: '',
          entrust_reward: [],
        },
        membershipTips: `ئادەتتىكى VIP ئەزالىقنىڭ تۈرلىرى ھەرخىل، باھاسى ئوخشاش بولمىغاچقا، پىرسەنت ياكى مۇقىم پۇل سوممىسى ئارقىلىق پايدا ئايرىش ئۇسۇلى ماس كەلمەي قالدى، شۇ ۋەجىدىن بىز پايدا سۇممىسىنى بىۋاستە VIP ئەزالىق تۈرى باشقۇرۇش ھالىتىدە بىر تەرەپ قىلدۇق. بىۋاستە VIP ئەزالىق تۈرى باشقۇرۇش ئورنىدىن ھەربىر VIP ئەزالىق تۈرىگە ئايرىم پايدا سوممىسىنى ئورنىتىڭ.`
      };
    },
    methods: {
      onSubmit(){
        this.updateUserReward(this.userId, this.formData)
          .then(()=>{
            this.$emit('updated');
          })
          .catch(()=>{
            this.$emit('failed');
            this.hideBusy('userBusy');
          })
      },
      addNewTag(e, key = 'entrust_reward'){
        let v = parseFloat(e);
        let item = {
          name: `${v} يۈەن `,
          value: v
        };
        this.formData[key].push(item);
      },
      addMembershipReward(e){
        this.addNewTag(e, 'membership_reward');
      },
      addEntrustReward(e){
        this.addNewTag(e);
      },
    }
  }
</script>

<style scoped>

</style>
